import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { SelectionTable } from "ProjectComponents/tableV2/selectionTable/SelectionTable";
import { quickSearchColumns, useColumns } from "Screens/services/tableConfig";
import { type AppState } from "Store";
import {
  getServices,
  getServicesInitial,
  resetSearch,
  setTableHiddenColumns,
  TABLE_TYPES,
} from "Store/services";
import { searchSpecShorthand } from "Utils";
import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface ISelectServiceScreen {
  selectedServices: number[];
  setSelectedServices: Dispatch<SetStateAction<number[]>>;
}

export const SelectServiceScreen: React.FC<ISelectServiceScreen> = ({
  selectedServices,
  setSelectedServices,
}) => {
  const dispatch = useDispatch();
  const {
    initialMeta,
    listMeta,
    list: servicesList,
    maxCount: listMaxCount,
    listUpdateLoading: tableLoading,
    searchData,
    filters,
    loading,
    hiddenColumns,
  } = useSelector((state: AppState) => state.services);

  const { unitOfMeasurement } = useSelector(
    (state: AppState) => state.auth.companyData,
  );

  useEffect(() => {
    dispatch(getServicesInitial(listMeta));

    return (): void => {
      dispatch(resetSearch());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useColumns(unitOfMeasurement);

  const onChangeColumnsView = useCallback(
    (values: GridColumnVisibilityModel) => {
      dispatch(
        setTableHiddenColumns(TABLE_TYPES.CREATE_ORDER_SERVICES_TABLE, values),
      );
    },
    [dispatch],
  );

  const handleTablePagination = useCallback(
    (currentPage: number, numberOfRows: number) => {
      dispatch(
        getServices({
          params: {
            ...listMeta,
            page: currentPage,
            pageLength: numberOfRows,
          },
          search: searchData,
          filters,
        }),
      );
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [listMeta, searchData, filters],
  );

  const handleTableSearch = useCallback(
    (query: string) => {
      const config = {
        params: listMeta,
        search: searchSpecShorthand(query, quickSearchColumns),
      };

      dispatch(getServices(config));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [listMeta],
  );

  return (
    <SelectionTable
      columnVisibilityModel={
        hiddenColumns[TABLE_TYPES.CREATE_ORDER_SERVICES_TABLE]
      }
      columns={columns}
      handlePagination={handleTablePagination}
      rowSelectionModel={selectedServices}
      onRowSelectionModelChange={(ids) => {
        setSelectedServices(ids as number[]);
      }}
      handleQuickSearch={handleTableSearch}
      loading={loading || tableLoading || initialMeta.loading}
      maxCount={listMaxCount}
      onColumnVisibilityModelChange={onChangeColumnsView}
      rows={servicesList}
      toolbarProps={{}}
    />
  );
};
