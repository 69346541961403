import { ImportResponseBody } from "@ero/app-common/v2/routes/models/import";
import { Dialog, DialogTitle } from "@mui/material";
import { addParcels } from "Api";
import { CloseButton } from "Components/closeButton/closeButton";
import { ParcelImportForm } from "ProjectComponents/parcelImport/parcelImportForm";
import { NotificationService } from "Services";
import { type AppState } from "Store";
import { getParcels } from "Store/parcels";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ImportParcelTableRow } from "../../types";
import ParcelsOverview from "../importOverview/parcelsOverviewModal";

type ImportParcelsModal = {
  isOpen: boolean;
  onClose: () => void;
};

export const ImportParcelsModal: React.FC<ImportParcelsModal> = ({
  isOpen,
  onClose,
}) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const { listMeta } = useSelector((state: AppState) => state.parcels);

  const [showOverview, setShowOverview] = useState(false);
  const [parcelData, setParcelData] = useState<ImportParcelTableRow[]>([]);
  const [importID, setImportID] = useState<number>();

  const showError = useCallback(() => {
    NotificationService.error(
      t("notification.errorTitle"),
      t("notification.error.parcelimport"),
    );
  }, [t]);

  const onImportSuccess = (responseData?: ImportResponseBody) => {
    setImportID(responseData?._id);

    const importedParcels: ImportParcelTableRow[] =
      responseData?.data?.map((parcel, index) => ({
        ...parcel,
        _id: index + 1,
      })) ?? [];
    setParcelData(importedParcels);

    onClose();
    setShowOverview(true);
  };

  const handleOverviewClose = () => {
    setShowOverview(false);
    onClose();
  };

  const handleAddSelectedParcels = async (parcels: ImportParcelTableRow[]) => {
    try {
      if (importID) {
        await addParcels(parcels, importID);
        dispatch(
          getParcels({
            params: listMeta,
            withSuccess: true,
          }),
        );
      } else {
        throw new Error("import data not found");
      }
    } catch (_) {
      showError();
    }

    handleOverviewClose();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>{t("parcels.createMultiple")}</DialogTitle>
        <CloseButton onClose={onClose} />
        <ParcelImportForm onSuccess={onImportSuccess} />
      </Dialog>
      {showOverview && (
        <ParcelsOverview
          isOpen={showOverview}
          onClose={handleOverviewClose}
          onAddSelectedParcels={handleAddSelectedParcels}
          parcelData={parcelData}
          setParcelData={setParcelData}
        />
      )}
    </>
  );
};
