import { App } from "App";
import React from "react";
import { createRoot } from "react-dom/client";
import { initSentry } from "Utils/sentry";
import * as serviceWorker from "./serviceWorker";

initSentry(
  import.meta.env.MODE,
  import.meta.env.VITE_SENTRY_DSN,
  import.meta.env.VITE_SENTRY_RELEASE,
  import.meta.env.VITE_DOMAIN,
);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
