import { ParcelHistoryResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { Avatar, Grid2, Typography } from "@mui/material";
import { AppState } from "Store";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface IProps {
  item: ParcelHistoryResponseBody["jobHistory"][0];
}

export const HistoryItem: React.FC<IProps> = ({ item }) => {
  const [t] = useTranslation();

  const language = useSelector((state: AppState) => state.auth.selectedLang);

  const endDate = useMemo(
    () =>
      new Intl.DateTimeFormat(language, {
        dateStyle: "medium",
        timeStyle: "short",
      })
        .format(new Date(item.end))
        .replace(",", ""),
    [item.end, language],
  );

  const { driverAvatar, driverName } = useMemo(() => {
    if (item.employees.length == 0) {
      return { driverAvatar: undefined, driverName: "" };
    }
    const driver = item.employees[0];
    return {
      driverAvatar: driver.avatar,
      driverName: `${driver.firstName} ${driver.lastName}`,
    };
  }, [item.employees]);

  return (
    (<Grid2
      container
      maxWidth="60%"
      spacing={5}
      justifyContent="center"
      alignItems="center"
      marginBottom="10px"
    >
      <Grid2 size={1}>
        <Avatar src={driverAvatar} />
      </Grid2>
      <Grid2 size={4}>
        <Grid2>
          <Typography variant="h6">{item.name}</Typography>
        </Grid2>
        <Grid2>
          <Typography variant="caption">{driverName}</Typography>
        </Grid2>
      </Grid2>
      <Grid2 size={2}>
        <Grid2>
          <Typography variant="caption">
            {t("parcels.jobHistory.completedOn")}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography variant="caption">{endDate}</Typography>
        </Grid2>
      </Grid2>
    </Grid2>)
  );
};
