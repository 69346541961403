import { Marker, MarkerF, type MarkerProps } from "@react-google-maps/api";
import markerIconDefault from "Assets/icons/map-marker-google_DEFAULT.svg";

import React, { forwardRef, useCallback, useMemo } from "react";
import { Coordinate } from "Types";

export type MarkerParcel = {
  _id?: number;
  name?: string;
  position: Coordinate;
};

export interface ParcelMarkerProps
  extends Omit<MarkerProps, "position" | "icon"> {
  parcel: MarkerParcel;
  itemOnClick?: (id: number) => void;
  customIcon?: string;
  showIcon: boolean;
  showLabel?: boolean;
}

const ParcelMarkerV2 = forwardRef<Marker, ParcelMarkerProps>((props) => {
  const {
    parcel,
    itemOnClick,
    onClick: externalOnClick,
    customIcon,
    showIcon,
    showLabel = true,
    ...markerProps
  } = props;

  const markerLabel: google.maps.MarkerLabel | string = useMemo(() => {
    if (!showLabel) {
      return "";
    }
    if (markerProps.label) {
      return markerProps.label;
    }
    return {
      text: parcel.name || "",
      color: "#fff",
      fontSize: "16px",
    };
  }, [markerProps.label, parcel.name, showLabel]);

  const icon: google.maps.Icon = useMemo(() => {
    if (!showIcon) {
      return { url: "", size: new google.maps.Size(0, 0) };
    }
    return {
      url: customIcon ?? markerIconDefault,
      //labelOrigin is used to set the position of the label when using custom markers
      labelOrigin: new google.maps.Point(0, 48),
      scaledSize: new google.maps.Size(36, 36),
    };
  }, [customIcon, showIcon]);

  const onClick = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (itemOnClick && parcel._id) itemOnClick(parcel._id);
      if (externalOnClick) externalOnClick(e);
    },
    [itemOnClick, parcel._id, externalOnClick],
  );

  return (
    <MarkerF
      {...markerProps}
      position={parcel.position}
      label={markerLabel}
      icon={icon}
      onClick={onClick}
    />
  );
});

ParcelMarkerV2.displayName = "ParcelMarkerV2";

export { ParcelMarkerV2 };
