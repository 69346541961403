export const ROUTES = {
  AUTH: {
    LOGIN: "/login",
    REGISTER: "/register",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    VERIFY_PASSWORD: "/verify-password",
    CONFIRM_REGISTRATION: "/confirm-registration",
  },
  MISC: {
    HELP: "/help",
    TUTORIAL: "/tutorial",
  },
  MAIN: {
    DASHBOARD: "/dashboard",
    PLANNING: "/planning",
    ORDERS: "/orders",
    EMPLOYEES: "/employees",
    CUSTOMERS: "/customers",
    SERVICES: "/services",
    MACHINES: "/machines",
    ORGANIZATION: "/organization",
    PARCELS: "/parcels",
    ORDER_DETAILS: {
      OVERVIEW: "/overview",
      JOBS: "/jobs",
    },
  },
  PROFILE: {
    ROOT: "/profile",
    VIEW: "/profile/view",
    RELEASENOTES: "/profile/releaseNotes",
  },
  EXTERNAL: "/external-access",
};

export enum LAYOUTS {
  PRIVATE = "private",
  PUBLIC = "public",
}
