import ErrorIcon from "@mui/icons-material/Error";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { NotFound } from "ProjectComponents";
import { type AppState } from "Store";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";

export const ErrorElement: React.FC = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const error = useRouteError();

  const { accessToken } = useSelector((state: AppState) => state.auth.tokens);

  const buttonText = accessToken
    ? t("error404.buttonToDashboard")
    : t("error404.buttonToLogin");

  const errorDialog = useMemo(() => {
    return (
      <Dialog open={true}>
        <ErrorIcon
          color="primary"
          fontSize={"large"}
          sx={{
            position: "absolute",
            left: 8,
            top: 16,
          }}
        />
        <DialogTitle sx={{ pl: 6 }}>
          {t("components.errorDialog.title")}
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">
            {t("components.errorDialog.text")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            variant="contained"
            onClick={() => navigate("/")}
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [buttonText, navigate, t]);

  const render = useMemo(() => {
    console.error(error);
    if (!isRouteErrorResponse(error) || error.status !== 404) {
      return errorDialog;
    }

    return <NotFound />;
  }, [error, errorDialog]);

  return render;
};
