import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { DeleteOutline } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { jobStatusFillColor } from "Constants";
import React from "react";

interface IJobItem {
  job: JobResponseBody;
  onClick: (event, id: number) => void;
  onDelete: (jobId: number) => void;
}

export const JobItem: React.FC<IJobItem> = ({ job, onClick, onDelete }) => (
  <>
    <ListItem
      disablePadding
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => onDelete(job._id)}
        >
          <DeleteOutline />
        </IconButton>
      }
      sx={{ borderLeft: `5px solid ${jobStatusFillColor[job.status]}` }}
    >
      <ListItemButton onClick={(event) => onClick(event, job._id)}>
        <ListItemText primary={job.name} secondary={job.parcel.name} />
      </ListItemButton>
    </ListItem>
    <Divider />
  </>
);
