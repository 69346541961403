import { ServiceOutside } from "@ero/app-common/models";
import { type EntitySpec } from "@ero/app-common/util/Spec";
import { apiInstance } from "App/axios";
import { FILE_UPLOAD_TIMEOUT, GET_ALL_PARAMS } from "Constants";
import {
  RequestBody,
  type ApiListRequestParams,
  type ImportReviewConfirmType,
  type MapColumnRequestType,
} from "Types";
import {
  type CREATE_SERVICE_DTO,
  type ServicesListResponse,
} from "Types/models/services";

export const getServices = ({
  params,
  search,
  filters,
  spec,
}: ApiListRequestParams<ServiceOutside>) =>
  apiInstance.post<ServicesListResponse>(
    "/services/all",
    { search, filters, spec },
    { params },
  );

export const getAllServices = (spec: EntitySpec<ServiceOutside> = []) =>
  apiInstance.post<ServicesListResponse>(
    "/services/all",
    { spec },
    { params: GET_ALL_PARAMS },
  );

export const createService = (data: CREATE_SERVICE_DTO) =>
  apiInstance.post("/services/create", data);

export const deleteServices = (ids: number[]) =>
  apiInstance.post("/services/delete", { ids });

export const updateService = (
  updateData: RequestBody<"/services/update">,
  id: number,
) => {
  const body = {
    ...updateData,
    _id: id,
  };
  return apiInstance.post("/services/update", body);
};

export const uploadServicesFile = (
  data: any,
  onUploadProgress: (progressEvent) => void,
) =>
  apiInstance.post("/services/bulk", data, {
    onUploadProgress,
    timeout: FILE_UPLOAD_TIMEOUT,
  });

export const mapNewServicesColumns = (data: MapColumnRequestType) =>
  apiInstance.post(
    "/services/fulfill",
    {
      identifier: data.identifier,
      relationships: data.relationships,
      preview: false,
    },
    { timeout: FILE_UPLOAD_TIMEOUT },
  );

export const mapExistedServiceColumns = (data: MapColumnRequestType) =>
  apiInstance.post(
    "/services/overwrite",
    { identifier: data.identifier, relationships: data.relationships },
    { timeout: FILE_UPLOAD_TIMEOUT },
  );

export const importServicesReviewConfirm = (data: ImportReviewConfirmType) =>
  apiInstance.post(
    "/services/overwriteCommit",
    { identifier: data.identifier, revert: data.revert },
    { timeout: FILE_UPLOAD_TIMEOUT },
  );

export const uploadFileToService = (
  serviceId: number,
  file: any,
  onUploadProgress: (progressEvent) => void,
) =>
  apiInstance.post(
    "/services/appendFile",
    { ...file, serviceId },
    {
      onUploadProgress,
      timeout: FILE_UPLOAD_TIMEOUT,
    },
  );

export const removeFileFromService = (serviceId: number, file: string) =>
  apiInstance.post("/services/removeFile", { file, serviceId });
