import { SIZE_FIELD } from "@ero/app-common/enums/sizeField";
import { PlotOutside } from "@ero/app-common/models";
import { UnitSymbol } from "@ero/app-common/util/convertArea";
import { ControlPoint } from "@mui/icons-material";
import { Grid2, Stack, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { Input } from "Components";
import { useFormikContext } from "formik";
import { useAllCustomerOptions } from "Hooks/customerOptions";
import { useDropdownValues } from "Hooks/dropdownValues";
import i18n from "i18n/i18n";
import { SelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/SelectWithAutoComplete";
import { StaticSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticSelectWithAutoComplete";
import { ParcelMap } from "ProjectComponents/parcelInformationModal/components/map/map";
import { FormikValues } from "ProjectComponents/parcelInformationModal/validationConfig";
import { PlotModal } from "ProjectComponents/plotModal";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { useSizeFieldOptions } from "./autoCompletes";

export const ParcelData: React.FC = () => {
  const [t] = useTranslation();

  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const { unitOfMeasurement } = useSelector(
    (store: AppState) => store.auth.companyData,
  );

  const [showPlotModal, setShowPlotModal] = useState(false);
  const [plotToEdit, setPlotToEdit] = useState<PlotOutside | undefined>(
    undefined,
  );

  const { fetchCustomerOptions } = useAllCustomerOptions();

  const openPlotModal = useCallback((plot?: PlotOutside) => {
    setPlotToEdit(plot);
    setShowPlotModal(true);
  }, []);

  const plotSubmitHandler = useCallback(
    (value: PlotOutside) => {
      const filtered = values.flurstuecke?.filter(
        (curr) => curr.flurstuecksnummer != value.flurstuecksnummer,
      );
      setFieldValue("flurstuecke", [...(filtered || []), value]);
    },
    [setFieldValue, values.flurstuecke],
  );

  const sizeFieldOptions = useSizeFieldOptions();

  const { fetchDropdownValues: fetchGrapeVarietyOptions } =
    useDropdownValues("grapeVariety");

  return (<>
    <Grid2 container spacing={2}>
      <Grid2 size={6}>
        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <Input
              name={"name"}
              type="text"
              label={t("general.labels.parcelName")}
              mandatory={true}
            />
          </Grid2>
          <Grid2 size={6}>
            <SelectWithAutoComplete
              name="customer"
              required
              fetchOptions={fetchCustomerOptions}
              disableClearable
              label={t("general.fields.customer")}
            />
          </Grid2>
          <Grid2 size={6}>
            <SelectWithAutoComplete
              name="grapeVariety"
              fetchOptions={fetchGrapeVarietyOptions}
              label={t("parcels.fields.grapeVariety")}
            />
          </Grid2>
          <Grid2 size={3}>
            <Input
              name="rowAmount"
              type="number"
              label={t("parcels.fields.rowAmount")}
              inputProps={{ inputProps: { min: 1 } }}
              placeholder={""}
            />
          </Grid2>
          <Grid2 size={3}>
            <Input
              name="rowGap"
              type="number"
              label={t("parcels.fields.rowGap")}
              inputProps={{ inputProps: { min: 0 } }}
              placeholder={""}
            />
          </Grid2>
          <Grid2 size={3}>
            <StaticSelectWithAutoComplete
              name="sizeField"
              options={sizeFieldOptions}
              label={t("parcels.fields.sizeField", {
                unit: UnitSymbol[unitOfMeasurement],
              })}
              disabledValues={[
                ...(!values.sizeImportedKAFL
                  ? [SIZE_FIELD.SIZE_IMPORTED_KAFL]
                  : []),
                ...(!values.sizeImportedNUFL
                  ? [SIZE_FIELD.SIZE_IMPORTED_NUFL]
                  : []),
              ]}
            />
          </Grid2>
          <Grid2 size={3}>
            {values.sizeField == SIZE_FIELD.SIZE_AUTOMATIC && (
              <Input
                name="sizeAutomatic"
                type="text"
                label={t("parcels.fields.sizeAutomaticUnit", {
                  unit: UnitSymbol[unitOfMeasurement],
                })}
                disabled={true}
              />
            )}
            {values.sizeField == SIZE_FIELD.SIZE_IMPORTED_KAFL && (
              <Input
                name={"sizeImportedKAFL"}
                type="number"
                label={t("parcels.fields.sizeImportedKaflUnit", {
                  unit: UnitSymbol[unitOfMeasurement],
                })}
                disabled={true}
              />
            )}
            {values.sizeField == SIZE_FIELD.SIZE_IMPORTED_NUFL && (
              <Input
                name={"sizeImportedNUFL"}
                type="number"
                label={t("parcels.fields.sizeImportedNuflUnit", {
                  unit: UnitSymbol[unitOfMeasurement],
                })}
                disabled={true}
              />
            )}
            {values.sizeField == SIZE_FIELD.SIZE_MANUAL && (
              <Input
                name={"sizeManual"}
                type="number"
                inputProps={{ inputProps: { min: 0 } }}
                label={t("parcels.fields.sizeManualUnit", {
                  unit: UnitSymbol[unitOfMeasurement],
                })}
              />
            )}
          </Grid2>
          <Grid2 size={3}>
            <Input
              name="plantAmount"
              type="number"
              label={t("general.fields.plantAmount")}
              placeholder={""}
              inputProps={{ inputProps: { min: 1 } }}
            />
          </Grid2>
          <Grid2 size={3}>
            <Input
              name="plantGap"
              type="number"
              label={t("general.fields.plantGap")}
              placeholder={""}
              inputProps={{ inputProps: { min: 0 } }}
            />
          </Grid2>
          <Grid2 size={12}>
            <Typography variant="h6">
              {t("parcels.locationInformation")}
            </Typography>
          </Grid2>
          <Grid2 size={6}>
            <Input
              name={"growingarea"}
              type="text"
              label={i18n.t("parcels.fields.growingarea")}
            />
          </Grid2>
          <Grid2 size={6}>
            <Input
              name={"region"}
              type="text"
              label={i18n.t("parcels.fields.region")}
            />
          </Grid2>
          <Grid2 size={6}>
            <Input
              name="largeRegion"
              type="text"
              size="medium"
              label={t("parcels.fields.regions")}
            />
          </Grid2>
          <Grid2 size={6}>
            <Input
              name="separatedregion"
              type="text"
              size="medium"
              label={t("parcels.fields.separatedregion")}
            />
          </Grid2>
          <Grid2 size={6}>
            <Input
              name={"regionette"}
              type="text"
              label={i18n.t("parcels.fields.regionette")}
            />
          </Grid2>
          <Grid2 size={12}>
            <Typography variant="h6">
              {t("parcels.plotInformation")}
            </Typography>
          </Grid2>
          <Grid2 size={6}>
            <Input
              name={"mark"}
              type="text"
              label={i18n.t("parcels.fields.gemarkung")}
            />
          </Grid2>
          <Grid2 size={3}>
            <Input
              name={"gemarkungsnummer"}
              type="number"
              label={i18n.t("parcels.fields.gemarkungNumber")}
            />
          </Grid2>
          <Grid2 size={3}>
            <Input
              name={"flnr"}
              type="number"
              label={i18n.t("parcels.fields.flurnummer")}
            />
          </Grid2>
          <Grid2 size={12}>
            <Typography variant="h6">
              {t("parcels.plotsContained")}
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {values.flurstuecke?.map((chipFlurstueck, index) => (
                <Chip
                  key={index}
                  label={`${chipFlurstueck.flurstuecksnummer}`}
                  clickable
                  onClick={() => {
                    openPlotModal(chipFlurstueck as PlotOutside);
                  }}
                  onDelete={() =>
                    setFieldValue(
                      "flurstuecke",
                      values.flurstuecke?.filter(
                        (flurstueck) =>
                          chipFlurstueck.flurstuecksnummer !==
                          flurstueck.flurstuecksnummer,
                      ),
                    )
                  }
                />
              ))}
              <IconButton
                onClick={() => {
                  openPlotModal(undefined);
                }}
              >
                <ControlPoint />
              </IconButton>
            </Stack>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 size={6}>
        <ParcelMap />
      </Grid2>
    </Grid2>
    <PlotModal
      submitHandler={plotSubmitHandler}
      isOpen={showPlotModal}
      onClose={() => setShowPlotModal(false)}
      plot={plotToEdit}
    />
  </>);
};
