import { LANGUAGE } from "@ero/app-common/enums/language";
import EditIcon from "@mui/icons-material/Edit";
import { Grid2, IconButton, Stack, TextField, Typography } from "@mui/material";
import { OneFieldForm } from "Components/oneFieldForm";
import { OneFieldFormSelect } from "Components/oneFieldForm/oneFieldFormSelect";
import { type AppState } from "Store";
import { changeLanguage, updateUserData } from "Store/auth";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordDialog } from "../changePasswordModal/changePasswordDialog";
import { getValidationRules } from "./validationConfig";

export const UserInformation: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { userData, success } = useSelector((state: AppState) => state.auth);
  const [changePasswordOpen, toggleChangePassword] = useState(false);

  const onSubmit = (values: Record<string, string>) => {
    dispatch(updateUserData(values));
  };

  const changeLanguageHandler = (lang: LANGUAGE) => {
    dispatch(changeLanguage(lang));
  };

  const validationRules = getValidationRules();

  const languageOptions = [
    {
      value: "us",
      label: "English",
    },
    {
      value: "de",
      label: "Deutsch",
    },
  ];

  return (
    <Grid2 container spacing={2} maxWidth={"lg"}>
      <Grid2 size={12}>
        <Typography variant="h4">
          {t("profile.userInformation.title")}
        </Typography>
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="firstName"
          value={userData.firstName}
          onSubmit={onSubmit}
          validationRules={validationRules.freeField}
          label={t("general.fields.firstName")}
          success={success}
        />
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="lastName"
          value={userData.lastName}
          onSubmit={onSubmit}
          validationRules={validationRules.freeField}
          label={t("general.fields.lastName")}
          success={success}
        />
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="email"
          value={userData.email}
          onSubmit={onSubmit}
          validationRules={validationRules.email}
          label={t("profile.userInformation.mail")}
          success={success}
        />
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="phone"
          value={userData.phone}
          onSubmit={onSubmit}
          validationRules={validationRules.phone}
          label={t("profile.userInformation.phone")}
          success={success}
        />
      </Grid2>
      <Grid2 size={6}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            value="**************"
            disabled
            label={t("profile.userInformation.password")}
            variant="outlined"
            fullWidth
          />
          <IconButton
            onClick={() => {
              toggleChangePassword(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </Stack>
      </Grid2>
      <Grid2 size={6}>
        <OneFieldFormSelect
          name="language"
          label="Sprache"
          success={success}
          onSubmit={(values) => {
            changeLanguageHandler(values.language);
          }}
          value={userData.language}
          options={languageOptions}
        />
      </Grid2>
      <ChangePasswordDialog
        open={changePasswordOpen}
        onClose={() => toggleChangePassword(false)}
      />
    </Grid2>
  );
};
