import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { getParcelColor } from "@ero/app-common/v2/utils/ParcelColor";
import { TrackPoint } from "@ero/app-common/v2/utils/TrackPoint";
import { Card, CardContent } from "@mui/material";
import { MapParcel, MapV2, TrackV2 } from "ProjectComponents";
import { AppState } from "Store";
import { markerIcons } from "Utils";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

interface IMapCard {
  job?: JobResponseBody;
}

const getPath = (track: TrackPoint[]) =>
  [...track]
    .sort((a, b) => a.timestamp - b.timestamp)
    .map((t) => ({
      lat: t.latitude,
      lng: t.longitude,
    }));

export const MapCard: React.FC<IMapCard> = ({ job }) => {
  const map = useRef<google.maps.Map>();

  const [zoom, setZoom] = useState(16);

  const { companyData } = useSelector((store: AppState) => store.auth);
  const track = useSelector(
    (state: AppState) => state.orderDetails.jobTelemetry.track,
  );

  const center = useMemo(() => {
    if (job?.parcel.position) {
      return job?.parcel.position;
    }
    if (job?.parcel.shape && job?.parcel.shape.length > 0) {
      return job?.parcel.shape[0];
    }
  }, [job]);

  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      map.current = mapInstance;
      if ((job?.parcel.shape?.length ?? 0) == 0) {
        return;
      }
      const bounds = new google.maps.LatLngBounds();
      job?.parcel.shape?.forEach((coord) => bounds.extend(coord));
      map.current.fitBounds(bounds);
    },
    [job?.parcel.shape],
  );

  const onZoomChanged = useCallback(() => {
    const currentZoom = map.current?.getZoom();
    if (currentZoom) setZoom(currentZoom);
  }, [map]);

  const showPolygon = useMemo(() => (zoom ? zoom > 14 : false), [zoom]);

  const path = useMemo(() => track && getPath(track), [track]);

  const parcelFigure = useMemo(() => {
    if (job) {
      return (
        <MapParcel
          key={job.parcel._id}
          parcel={job.parcel}
          showPolygon={showPolygon}
          fillColor={getParcelColor(job.status)}
          markerProps={{ customIcon: markerIcons[job.status] }}
        />
      );
    }
  }, [job, showPolygon]);

  return (
    <Card sx={{ height: 400 }}>
      <CardContent
        sx={{
          height: "100%",
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <MapV2
          id="job-details-map"
          center={center ?? companyData.machinePosition}
          zoom={zoom}
          onZoomChanged={onZoomChanged}
          onLoad={onLoad}
        >
          {parcelFigure}
          {track && length > 0 && (
            <TrackV2
              path={path}
              options={{
                strokeColor: getParcelColor(job?.status || -1),
                strokeOpacity: 1.0,
                strokeWeight: 2,
                zIndex: 999,
              }}
            ></TrackV2>
          )}
        </MapV2>
      </CardContent>
    </Card>
  );
};
