import {
  createJobV2,
  deleteJobV2,
  downloadDeliveryNote,
  getJobTelemetryV2,
  getOrderById,
  updateJobTelemetryV2,
  updateJobV2,
  updateOrder,
} from "Api";
import { errorToast } from "Services";
import { downloadFile } from "Utils";
import i18n from "i18next";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  IDeleteJob,
  ORDER_DETAILS_ACTION_TYPES,
  type IAddJob,
  type IGetInitialOrderDetailsRequestAction,
  type IGetJobTelemetry,
  type IUpdateJob,
  type IUpdateJobTelemetry,
  type IUpdateOrderRequestAction,
} from "./action-types";
import {
  getOrderDetailsInitialError,
  getOrderDetailsInitialSuccess,
  resetMeta,
  setError,
  setJobTelemtry,
  setJobTelemtryError,
  setJobTelemtryLoading,
  setLoading,
  setSuccess,
  updateJobError,
  updateJobSuccess,
  updateOrderSuccess,
} from "./actions";

export function* getInitialOrderDetailsSaga({
  payload,
}: IGetInitialOrderDetailsRequestAction) {
  const { id } = payload;

  try {
    yield put(setLoading());

    const { data } = yield call(getOrderById, id);

    yield put(getOrderDetailsInitialSuccess(data));

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(getOrderDetailsInitialError());

    yield put(setError());

    errorToast(error);
  }
}

export function* updateOrderSaga({ payload }: IUpdateOrderRequestAction) {
  try {
    yield put(setLoading());

    const store = yield select();

    const { data } = yield call(
      updateOrder,
      store.orderDetails.order._id,
      payload,
    );

    yield put(updateOrderSuccess(data));

    yield put(setSuccess());
    yield put(resetMeta());
  } catch (error) {
    yield put(setError());
    errorToast(error);
  }
}

export function* downloadDeliveryNoteSaga() {
  try {
    yield put(setLoading());

    const store = yield select();

    const { data } = yield call(
      downloadDeliveryNote,
      store.orderDetails.order._id,
    );

    if (data.status === 413) {
      errorToast(i18n.t("orders.details.deliveryNote.contentError"));
    } else {
      downloadFile(
        `data:application/pdf;base64,${data}`,
        i18n.t("orders.deliveryNoteFilename", {
          order_number: store.orderDetails.order.name,
        }),
      );
    }

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* updateJobSaga({ payload }: IUpdateJob) {
  const updateData = payload;

  try {
    const { data: updatedJob } = yield call(updateJobV2, updateData);

    if (!updatedJob) yield put(updateJobError());
    else yield put(updateJobSuccess(updatedJob));
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* addJobSaga({ payload }: IAddJob) {
  try {
    yield put(setLoading());

    const store = yield select();
    const order = store.orderDetails.order;

    yield call(createJobV2, payload);

    const { data: updatedOrderData } = yield call(getOrderById, order._id);

    yield put(updateOrderSuccess(updatedOrderData));

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (err) {
    yield put(setError());
    errorToast(err);
  }
}

export function* deleteJobSaga({ payload }: IDeleteJob) {
  const { id } = payload;

  try {
    yield put(setLoading());
    yield call(deleteJobV2, id);

    const store = yield select();

    const order = store.orderDetails.order;

    const { data: updatedOrderData } = yield call(getOrderById, order._id);

    yield put(updateOrderSuccess(updatedOrderData));

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* getJobTelemetrySaga({ payload }: IGetJobTelemetry) {
  const { id } = payload;

  try {
    yield put(setJobTelemtryLoading());

    const { data } = yield call(getJobTelemetryV2, id);

    yield put(setJobTelemtry(data));
  } catch (error) {
    yield put(setJobTelemtryError());

    errorToast(error);
  }
}

export function* updateJobTelemetrySaga({ payload }: IUpdateJobTelemetry) {
  const { id, telemetry } = payload;

  try {
    yield put(setJobTelemtryLoading());

    const { data } = yield call(updateJobTelemetryV2, id, telemetry);

    yield put(setJobTelemtry(data));
  } catch (error) {
    yield put(setJobTelemtryError());

    errorToast(error);
  }
}

export default function* orderDetailsSagas() {
  yield all([
    takeEvery(
      ORDER_DETAILS_ACTION_TYPES.GET_INITIAL_ORDER_DETAILS_REQUEST,
      getInitialOrderDetailsSaga,
    ),
    takeEvery(ORDER_DETAILS_ACTION_TYPES.UPDATE_ORDER_REQUEST, updateOrderSaga),
    takeEvery(
      ORDER_DETAILS_ACTION_TYPES.DOWNLOAD_DELIVERY_NOTE,
      downloadDeliveryNoteSaga,
    ),
    takeEvery(ORDER_DETAILS_ACTION_TYPES.ADD_JOB, addJobSaga),
    takeEvery(ORDER_DETAILS_ACTION_TYPES.DELETE_JOB, deleteJobSaga),
    takeEvery(ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB, updateJobSaga),
    takeEvery(
      ORDER_DETAILS_ACTION_TYPES.GET_JOB_TELEMETRY,
      getJobTelemetrySaga,
    ),
    takeEvery(
      ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB_TELEMETRY,
      updateJobTelemetrySaga,
    ),
  ]);
}
