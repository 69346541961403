import { Box, Stack, Typography } from "@mui/material";
import Logo from "Assets/Logo_EROplan_RGB.png";
import LogoNegativ from "Assets/Logo_EROplan_negativ_RGB.png";
import { ROUTES } from "Constants";
import { DarkModeSwitch, LangChooseSwitch } from "ProjectComponents";
import { getValidationSchema, initialValues } from "Screens/login/formConfig";
import { FormContent } from "Screens/login/formContent";
import { AppState } from "Store";
import { login, resetMeta } from "Store/auth";
import { type LOGIN_DTO } from "Types";
import { Formik } from "formik";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const Login: React.FC = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { colorMode, success } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    return (): void => {
      dispatch(resetMeta());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      navigate(`${ROUTES.MAIN.DASHBOARD}`, { replace: true });
    }
  }, [success, navigate]);

  const handleFormSubmit = useCallback((values: LOGIN_DTO): void => {
    dispatch(login(values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <img
        src={colorMode === "light" ? Logo : LogoNegativ}
        alt="logo"
        style={{ maxWidth: "100%", padding: 32 }}
      />
      <Box>
        <Stack direction={"row"} justifyContent={"center"} spacing={2} pb={2}>
          <LangChooseSwitch />
          <DarkModeSwitch />
        </Stack>
        <Typography variant="h4" textAlign="center" pb={2}>
          {t("auth.common.welcomeMessage")}
        </Typography>
        <Typography variant="body1" textAlign="center" pb={2}>
          {t("auth.login.caption")}
        </Typography>
      </Box>
      <Formik
        validationSchema={getValidationSchema()}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
      >
        <FormContent />
      </Formik>
    </>
  );
};
