import { PERM } from "@ero/app-common/enums";
import { LANGUAGE } from "@ero/app-common/enums/language";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Grid2,
  Switch,
  Tooltip,
} from "@mui/material";
import { AvatarUpload, Input } from "Components";
import { StaticSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticSelectWithAutoComplete";
import { LocalEmployeeType } from "Store/employees/specs";
import { OptionType } from "Types";
import { resizeTo } from "Utils";
import { Field, useFormikContext } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChangePasswordModal } from "../../components";

interface BasicDataScreenProps {
  editMode: boolean;
}

type LocalEmployeeTypeExtended = LocalEmployeeType & {
  manualPasswordSelection: boolean;
};

const languageOptions: OptionType[] = [
  { value: LANGUAGE.DE, label: "Deutsch" },
  { value: LANGUAGE.EN, label: "English" },
];

export const BasicData: React.FC<BasicDataScreenProps> = ({
  editMode,
}: BasicDataScreenProps) => {
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [t] = useTranslation();

  const { values, setFieldValue } =
    useFormikContext<LocalEmployeeTypeExtended>();

  const handleAvatarUpload = useCallback(
    (value: File): void => {
      const resizedImage = resizeTo(value, 150);
      resizedImage.then((image: string) => {
        setFieldValue("avatar", image);
      });
    },
    [setFieldValue],
  );

  const permissionOptions = useMemo(
    () => [
      { value: PERM.ORGANIZER, label: t("employees.permission.organizer") },
      { value: PERM.DRIVER, label: t("employees.permission.driver") },
    ],
    [t],
  );

  return (
    <Container maxWidth={"xl"}>
      <Grid2 container spacing={2} justifyContent="flex-end">
        <Grid2 size={editMode ? 10 : 12}>
          <AvatarUpload
            name="employee"
            letterAvatar={
              (values.firstName?.[0] ?? "") + (values.lastName?.[0] ?? "")
            }
            image={values.avatar || undefined}
            onSubmit={handleAvatarUpload}
          />
        </Grid2>
        {editMode && (
          <Grid2
            container
            alignItems={"flex-end"}
            justifyContent="flex-end"
            size={2}
          >
            <Grid2>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsChangePasswordModalOpen(true)}
              >
                {t("changePassword.title")}
              </Button>
            </Grid2>
          </Grid2>
        )}
        <Grid2
          container
          spacing={2}
          alignItems="center"
          alignContent="flex-start"
          size={7}
        >
          <Grid2 size={6}>
            <Input
              name="userName"
              label={t("general.fields.userName")}
              mandatory={true}
            />
          </Grid2>
          <Grid2 size={6}>
            <Input
              name="email"
              label={t("general.fields.email")}
              mandatory={!values.manualPasswordSelection}
            />
          </Grid2>
          {!editMode && (
            <>
              <Grid2 size={6}>
                <Input
                  disabled={!values.manualPasswordSelection}
                  EndIcon={showPassword ? <VisibilityOff /> : <Visibility />}
                  onIconClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  label={t("general.fields.password")}
                  mandatory={values.manualPasswordSelection}
                />
              </Grid2>
              <Grid2 size={6}>
                <Tooltip title={t("employees.passwordSwitchTooltip")}>
                  <FormGroup>
                    <FormControlLabel
                      label={t("employees.fields.setPasswordToggle")}
                      control={
                        <Field name="manualPasswordSelection">
                          {({ field }) => (
                            <Switch
                              checked={field.value}
                              {...field}
                              onChange={(value) => {
                                setFieldValue("password", "");
                                field.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                      }
                    />
                  </FormGroup>
                </Tooltip>
              </Grid2>
            </>
          )}
          <Grid2 size={6}>
            <StaticSelectWithAutoComplete
              name="permission"
              label={t("employees.fields.permission")}
              options={permissionOptions}
              disableClearable
              required={true}
            />
          </Grid2>
          <Grid2 size={6}>
            <StaticSelectWithAutoComplete
              name="language"
              label={t("general.fields.language")}
              options={languageOptions}
            />
          </Grid2>
          <Grid2 size={6}>
            <Input name="firstName" label={t("general.fields.firstName")} />
          </Grid2>
          <Grid2 size={6}>
            <Input name="lastName" label={t("general.fields.lastName")} />
          </Grid2>
          <Grid2 size={6}>
            <Input name="phone" label={t("general.fields.phone")} />
          </Grid2>
          <Grid2 size={6}>
            <Input
              name="qualification"
              label={t("employees.fields.qualification")}
            />
          </Grid2>
        </Grid2>
        <Grid2 size={5}>
          <Input
            name="notes"
            label={t("general.fields.notes")}
            multiline
            rows={18}
          />
        </Grid2>
        {editMode && (
          <ChangePasswordModal
            open={isChangePasswordModalOpen}
            employeeId={values._id}
            onClose={() => setIsChangePasswordModalOpen(false)}
          />
        )}
      </Grid2>
    </Container>
  );
};
