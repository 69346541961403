import { Box, Grid2 } from "@mui/material";
import { Input } from "Components";
import { useAllCustomerOptions } from "Hooks/customerOptions";
import { SelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/SelectWithAutoComplete";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface IGeneralScreen {
  setSelectedParcels: Dispatch<SetStateAction<number[]>>;
}

export const GeneralScreen: React.FC<IGeneralScreen> = ({
  setSelectedParcels,
}) => {
  const [t] = useTranslation();

  const { fetchCustomerOptions } = useAllCustomerOptions();

  return (
    <Box display="flex" justifyContent="center">
      <Grid2 container spacing={5} justifyContent="center" padding={2}>
        <Grid2
          size={{
            xs: 12,
            sm: 10,
          }}
        >
          <Input name="name" label={t("general.labels.orderName")} />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 10,
          }}
        >
          <SelectWithAutoComplete
            name="customer"
            label={t("general.labels.customer")}
            fetchOptions={fetchCustomerOptions}
            onChange={() => setSelectedParcels([])}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 10,
          }}
        >
          <Input
            multiline
            rows={7}
            name="notes"
            label={t("general.fields.notes")}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};
