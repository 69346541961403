import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import { Fab, Tooltip } from "@mui/material";
import { useFullscreen } from "Hooks/fullscreen";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IFullScreenControlV2 {
  mapRef: React.MutableRefObject<google.maps.Map | undefined>;
}

export const FullScreenControlV2: React.FC<IFullScreenControlV2> = ({
  mapRef,
}) => {
  const [t] = useTranslation();

  const [mapElement, setMapElement] = useState(mapRef.current?.getDiv());

  useEffect(() => {
    setMapElement(mapRef.current?.getDiv());
  }, [mapRef]);

  const { isFullscreen, requestFullscreen, exitFullscreen } =
    useFullscreen(mapElement);

  return (
    <Tooltip title={t("components.map.controlButtons.fullscreen") as string}>
      <Fab onClick={isFullscreen ? exitFullscreen : requestFullscreen}>
        {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
      </Fab>
    </Tooltip>
  );
};
