import { useAllCustomerOptions } from "Hooks/customerOptions";
import { SelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/SelectWithAutoComplete";
import { OptionType } from "Types";
import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const CustomerInput: React.FC = () => {
  const [t] = useTranslation();

  const { fetchCustomerOptions } = useAllCustomerOptions();

  const { setFieldValue } = useFormikContext();

  const customerOnChange = useCallback(
    (selectedOption: OptionType | null) => {
      return setFieldValue("email", selectedOption?.meta?.email ?? "");
    },
    [setFieldValue],
  );

  return (
    <SelectWithAutoComplete
      name="customer"
      fetchOptions={fetchCustomerOptions}
      label={t("general.fields.customer")}
      onChange={customerOnChange}
      sx={{ mt: 1, mb: 1 }}
    />
  );
};
